<template>
  <v-row
    align="center"
    justify="center"
    :style="{ 'margin-left': marginChange, 'margin-right': marginChange }"
  >
    <v-col cols="12" sm="8" md="5">
      <v-card class="mt-12 mx-auto py-4 elevation-6">
        <v-card
          color="blue-grey darken-4"
          class="mx-auto my-n10 elevation-6"
          max-width="calc(100% - 32px)"
        >
          <v-card-title class="font-weight-light white--text center">
            Login
            <v-spacer />
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  elevation="4"
                  dark
                  ripple
                  x-small
                  color="primary"
                  v-on="on"
                  @click="clearAndPush"
                >
                  <v-icon>$mdiAccountPlus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(content.registrationBtn) }}</span>
            </v-tooltip>
          </v-card-title>
        </v-card>
        <v-card-text class="pt-12 pb-0">
          <div class="title font-weight-light mb-2">
            {{ $t(content.heading) }}
          </div>
          <div class="subheading font-weight-light grey--text">
            <p>
              {{ $t(content.bodytext) }}
            </p>
          </div>
          <v-form v-model="isValid" @submit.prevent="loginUser(loginData)">
            <v-text-field
              :label="$t(content.usernameLabel)"
              name="username"
              id="username"
              prepend-icon="$mdiAccount"
              spellcheck="false"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              :hint="$t(content.usernameHint)"
              required
              :rules="emailRules"
              v-model="loginData.email"
            />
            <v-text-field
              id="pw"
              :label="$t(content.pwLabel)"
              name="pw"
              prepend-icon="$mdiLock"
              :append-icon="showpw ? '$mdiEye' : '$mdiEyeOff'"
              :type="showpw ? 'text' : 'password'"
              spellcheck="false"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              required
              :rules="passwordRules"
              v-model="loginData.pw"
              @click:append="showpw = !showpw"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                type="submit"
                :disabled="!isValid"
                block
                rounded
                color="primary"
                elevation="6"
                >Login
              </v-btn>
            </v-card-actions>
          </v-form>
          <alertError class="mt-0" />
          <v-banner class="mt-3" two-line elevation="3" rounded>
            <v-icon slot="icon" size="30" color="primary" class="ml-2">
              $mdiInformation
            </v-icon>
            <v-sheet class="text-caption grey--text">
              {{ $t(content.footnote1) }}
              <router-link to="/registration">
                {{ $t(content.footnote2) }}
              </router-link>
            </v-sheet>
            <v-sheet class="text-caption grey--text">
              <router-link to="/passwordreset">
                {{ $t(content.lostPassword) }}
              </router-link>
            </v-sheet>
          </v-banner>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-console */
import confLogin from "../conf/confLogin";
import AlertError from "../components/AlertError";
import { mapGetters, mapActions, mapMutations } from "vuex";
import i18n from "../plugins/i18n";

export default {
  name: "Login",
  components: { AlertError },
  data: () => ({
    content: { ...confLogin },
    showpw: false,
    isValid: true,
    emailRules: [
      v => !!v || i18n.t(confLogin.emailRules1),
      v => /.+@.+/.test(v) || i18n.t(confLogin.emailRules2)
    ],
    passwordRules: [v => !!v || i18n.t(confLogin.passwordRules)],
    loginData: {
      email: "",
      pw: ""
    }
  }),
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),

    marginChange() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        default:
          return "-12px";
      }
    }
  },

  created() {
    this.checkStatus();
  },

  updated() {
    this.checkStatus();
  },

  watch: {
    isAuthenticated(val) {
      if (val === true) {
        this.$router.push({ name: "usercompany" }).catch(e => e);
      }
    },
    immediate: true
  },

  methods: {
    ...mapActions({
      loginUser: "auth/loginUser",
      logout: "auth/logout"
    }),
    ...mapMutations({
      global_clearError: "global_clearError",
      set_status: "auth/set_status"
    }),

    clearAndPush() {
      this.global_clearError();
      this.set_status(null);
      this.$router.push("registration").catch(e => e);
    },
    checkStatus() {
      if (this.isAuthenticated && this.$route.path === "/") {
        this.$router.push("usercompany").catch(e => e);
      } /*else if (!this.isAuthenticated) {
        this.logout();
      }*/
    }
  }
};
</script>
